<template>
  <v-dialog
    v-model="isVisible"
    persistent
    max-width="600px"
  >
    <v-card>
      <v-card-title>
        <span class="headline">Add Contact to Groups</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              cols="12"
            >
              <v-select
                v-model="selectedGroups"
                :items="getAvailableGroupsForSelectedContact()"
                label="Select a Groups*"
                item-text="name"
                item-value="id"
                :menu-props="{ top: true, offsetY: true }"
                required
                multiple
                chips
                :error="!(selectedGroups && selectedGroups.length) && isFormDirty"
              />
            </v-col>
          </v-row>
        </v-container>
        <small>* indicates required field</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="secondary"
          text
          @click="close"
        >
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          text
          :disabled="!(selectedGroups && selectedGroups.length)"
          @click="add"
        >
          Add
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-snackbar
      v-model="snackbar"
    >
      {{ notification }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-dialog>
</template>

<script>
  import HttpConnector from '../../../utils/http-connector'

  export default {
    name: 'AddContactToGroupsDialog',
    props: {
      groups: {
        type: Array,
        default: () => [],
      },
      allContacts: {
        type: Array,
        default: () => [],
      },
      isVisible: {
        type: Boolean,
        default: false,
      },
      contact: {
        type: Array,
        default: null,
      },
    },
    data: () => ({
      httpConnector: new HttpConnector(),
      apiEndpoint: process.env.VUE_APP_API_BASE_URL,
      apiCode: process.env.VUE_APP_API_CODE,
      googleClientId: process.env.VUE_APP_GOOGLE_PEOPLE_API_CLIENT_ID,
      googleApiKey: process.env.VUE_APP_GOOGLE_PEOPLE_API_KEY,
      user: null,
      dialog: false,
      selectedGroups: [],
      field: {
        file: null,
        groupId: '',
      },
      isFormDirty: false,
      snackbar: false,
      notification: 'Unknown',
    }),

    watch: {
      isVisible (val) {
        this.selectedGroups = []
      },

    },
    methods: {

      getAvailableGroupsForSelectedContact () {
        if (this.contact) {
          const selectedContactId = this.contact.id
          const groupsToRemove = []
          this.allContacts.forEach(contact => {
            if (contact.id === selectedContactId) {
              contact.groups.forEach(group => {
                groupsToRemove.push(group.groupId)
              })
            }
          })
          return this.groups.filter(group => groupsToRemove.indexOf(group.id) === -1)
        } else {
          return []
        }
      },

      async add () {
        try {
          const calls = []
          this.selectedGroups.forEach(group => {
            calls.push(this.httpConnector.makeRequest('post', `${this.apiEndpoint}groups/${group}/add?ContactId=${this.contact.id}&code=${this.apiCode}`))
          })
          this.$emit('on-submit')
          await Promise.all(calls)
          this.$emit('on-success')
        } catch (error) {
          this.$emit('on-error')
        }
      },

      close () {
        this.$emit('on-close')
      },

    },
  }
</script>
